<template>
    <div class="warp">
        <div>
            <section class="mipay">
                <div class="img-bg"></div>
                <div class="content">
                    <h3>Mi Pay<br/>无需打开App就支付</h3>
                    <span class="desc">在钱包App中绑定银行卡，即可使用Mi Pay进行便捷支付。付款时，双击电源键即可调出刷卡/二维码主扫/被扫界面，无需打开App就支付。硬件级金融防护支付便捷又安全</span>
                    <div class="video" id="playVideo" @click="videoModal = true">
                        <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay/play.svg" alt="play">
                        <span>点击视频展示</span>
                    </div>
                </div>
            </section>
            <div class="main-title">什么地方可以使用Mi Pay？</div>
            <section>
                <div class="content-part">
                    <div class="text">
                        <p class="title-part">线下海量商户</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">线下覆盖千万商户，只要带有银联闪付/银联二维码标识的商户，都可以使用Mi Pay支付。</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro"><a class="link" target="_blank" href="https://yunshanfu.unionpay.com/mobile/store">能用Mi Pay的部分商户</a></span>
                        </div>
                    </div>
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/offline-merchants.png" alt="">
                </div>
            </section>
            <section>
                <div class="content-part" style="padding: 0 10vw;">
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/popular-online-app.png" alt="">
                    <div class="text">
                        <p class="title-part">线上热门App</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">支持200多家线上商户App在线支付，覆盖热门生活场景，还有不定期优惠。（更多优惠信息可前往“钱包”App查看）</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro"><a class="link" target="_blank" href="https://yunshanfu.unionpay.com/mobile/store">哪些App能用Mi Pay</a></span>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="content-part">
                    <div class="text">
                        <p class="title-part">手机闪付乘公交地铁</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">Mi Pay银行卡已支持300多个城市刷手机乘公交地铁。</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro"><a class="link" target="_blank" href="https://contentcenter-drcn.dbankcdn.com/cch5/huaweipaycdn/bankcard_supportcitylist/index.html">哪些城市能用Mi Pay乘坐公交</a></span>
                        </div>
                    </div>
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/mobile-to-take-the-subway.png" alt="">
                </div>
            </section>
            <div class="main-title">如何使用Mi Pay付款？</div>
            <section>
                <div class="content-part" style="padding: 0 10vw;">
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/offline-credit-card.png" alt="">
                    <div class="text">
                        <p class="title-part">双击电源键，线下快捷刷卡</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">双击电源键、验证指纹，即可快速银行卡刷卡和银联付款码两大支付方式，刷卡/扫码轻松搞定；点击“扫一扫”按钮，即可快速扫描微信、银联等主流收款码</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">无需打开App就支付</span>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="content-part">
                    <div class="text">
                        <p class="title-part">众多热门App线上支付，优惠享不停</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">线上支付时，在支付页面选择“Mi Pay”支付方式 ，确认付款即可</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">200+热门App，享受不定期优惠活动</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">系统级支付体验，流畅顺滑</span>
                        </div>
                    </div>
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/discount.png" alt="">
                </div>
            </section>
            <section>
                <div class="content-part" style="padding: 0 10vw;">
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/pay.png" alt="">
                    <div class="text">
                        <p class="title-part">碰一碰就支付</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">手机亮屏解锁，靠近“碰一碰NFC”标签，即可快速支付</span>
                        </div>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">极简操作、快捷付款</span>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="content-center grey">
                    <p class="title-c">快速绑定，手机就是银行卡</p>
                    <p class="desc-c">在钱包App中绑定Mi Pay，即可在支持银联闪付、银联付款码、手机App应用内支付、碰一碰银联标签支付等场景使用</p>
                    <img  class="img-c" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/fast-bind.png" alt="fast-binding">
                </div>
            </section>
            <section>
                <div class="banks">
                    <img class="logo" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay/mipay-logo.png" alt="">
                    <p class="title">合作银行</p>
                    <p class="desc">Mi Pay可以在带有银联“云闪付”标识的POS机和支付终端设备上使用，现在已有数百万终端支持银联云闪付</p>
                    <ul>
                        <li v-for="(bank, index) in bankList" :key="index">
                            <img :src="bank" alt="">
                        </li>
                    </ul> 
                    <p class="more">
                        <span @click="checkMore('Banks', '[Mi Pay页面]查看更多合作银行')">查看更多合作银行 <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/check-more.svg" alt=""></span>
                    </p>
                </div>
            </section>
            <section>
                <div class="support-device grey">
                    <p class="title">支持设备</p>
                    <Loading v-if="!deviceList.length" height="20"/>
                    <div class="phone-list">
                        <ul>
                            <li v-for="(device, index) in deviceList" :key="index">
                                <img :src="device.deviceUrl" alt="">
                                <p>{{device.deviceName}}</p>
                            </li>
                        </ul>
                        <p class="more">
                            <span @click="checkMore('Devices', '[Mi Pay页面]查看更多机型及刷卡位置')">查看更多机型及刷卡位置 <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/check-more.svg" alt=""></span>
                        </p>
                    </div>
                </div>
            </section>
            <section v-if="mipayExplainList.length">
                <ul class="explain">
                    <li v-for="(item, index) in mipayExplainList" :key="index">{{item}}</li>
                </ul>
            </section>
            <Modal
                width="50"
                v-model="videoModal"
                title="科技，像魔术般不可思议"
                footer-hide>
                <video controls id="video-ele">
                    <source src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay/bankCard.mp4" type="video/mp4">
                    <p>Your browser doesn't support HTML5 video. Here is
                        a <a href="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay/bankCard.mp4">link to the video</a> instead.</p>
                </video>
            </Modal>
        </div>
    </div>
</template>

<script>
    const imgBase = 'https://did-cdn.pay.xiaomi.com/thumbnail/jpeg/q80'
    const banks = [
        `${imgBase}/NextPay/08cbd48c690df531f212b25c2d81d95b20942780e`,
        `${imgBase}/NextPay/08cbd48c690df531f012bb5c2881d05b2d942780e`,
        `${imgBase}/NextPay/08cbd48c690df531f012bc5c2781da5b2b942780e`,
        `${imgBase}/NextPay/02cbde5c6906f4318212ba5c2831d05128df28b78`,
        `${imgBase}/NextPay/08cbd48c690df631f012b85c2781df5b25942780e`,
        `${imgBase}/NextPay/0fcbde4c6909f4315212b25c2831d05928df08b78`,
        `${imgBase}/NextPay/08cbd48c690df531f112bf5c2581d25b2b942780e`,
        `${imgBase}/NextPay/07cbde4c6909f4315212b05c2841d05f28df78b78`,
        `${imgBase}/NextPay/08cbd48c690df531f012b55c2b81d25b20942780e`,
        `${imgBase}/NextPay/06cbde4c6909f4315212b05c2861d05728df48b78`,
        `${imgBase}/NextPay/08cbd48c690df531f012b35c2281da5b2b942780e`,
        `${imgBase}/NextPay/08cbd48c690df531f012b25c2281d05b2e942780e`,
        `${imgBase}/NextPay/05cbde4c6909f4315212b05c2831d05d28df28b78`,
        `${imgBase}/NextPay/04cbde4c6909f4316212bd5c2891d05d28dfb8b78`,
        `${imgBase}/NextPay/08cbd48c690df531f012b15c2181d45b26942780e`,
    ]
    import axios from 'axios'
    import { quickCustomPropToTsmWebsite } from '@/common/sensorsSdk'
    import Loading from "@/components/Loading.vue";
    export default {
        name: 'Mipay',
        data () {
            return {
                videoModal: false,
                deviceList: [],
                bankList: banks,
                mipayExplainList: []
            }
        },
        components: {
            Loading
        },
        async mounted () {
            quickCustomPropToTsmWebsite({websiteName: 'Mi Pay'})
            const deviceList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/devices.json?num=${Math.random()}`)
            const mipayExplainList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/mipayExplainList.json?num=${Math.random()}`)
            this.deviceList = deviceList.data.slice(0, 8)
            this.mipayExplainList = mipayExplainList.data
        },
        methods: {
            checkMore(routeName, text) {
                quickCustomPropToTsmWebsite({websiteClick: text})
                this.$router.push({name: routeName})
            }
        }
    }
</script>

<style scoped>
.warp section .img-bg{
    width: 100%;
    height: 41vw;
}
.warp section .content{
    z-index: 9;
    position: absolute;
    top: 16vw;
    left: 15vw;
    width: 30vw;
}
.warp section .content h3{
    font-size: 3.2vw;
    font-weight: 500;
}
.link::after{
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    border-top: 2px solid #2d8cf0;
    border-right: 2px solid #2d8cf0;
    transform: rotate(45deg);
    position: relative;
    top: -1px;
    margin-left: 4px;
    transition: .6s ease;
    border-radius: 2px;
}
.link:hover::after{
    border-top: 2px solid #57a3f3;
    border-right: 2px solid #57a3f3;
    margin-left: 8px;
    transition: .6s ease;
}
.main-title{
    font-size: 2.3vw;
    font-weight: 500;
    text-align: center;
    margin: 4vw 0 0;
}
.warp section .content p.title{
    font-size: 2vw;
}
.warp section .content span.desc{
    width: 80%;
    display: block;
    font-size: .9vw;
    margin-top: .5vw;
}
.warp section.mipay .img-bg{
    background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/mipay.jpg") no-repeat;
    background-size: cover;
}
.warp section.quickly-pay .img-bg{
    background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay/double-click-power.jpg") no-repeat;
    background-size: cover;
}
.video{
    position: relative;
    display: inline-block;
    background: rgba(0,0,0,0.10);
    border: 1px solid rgba(0,0,0,0.40);
    border-radius: 1.1vw;
    padding: .35vw 1.0vw;
    font-size: .9vw;
    letter-spacing: .3px;
    margin-top: 2vw;
    opacity: .8;
    cursor: pointer;
}
.video img{
    position: absolute;
    width: 1vw;
    top: .45vw;
}
.video span{
    padding-left: 1.3vw;
}
.content-part{
    display: flex;
    justify-content: space-around;
    padding: 6vw 10vw;
    align-items: center;
}
.content-part .text .title-part{
    font-size: 1.9vw;
    font-weight: 500;
    margin-bottom: .7vw;
}
.text .circle{
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    border: 3.5px solid #ddd;
    margin-right: .5vw;
}
.text .intro{
    font-size: .9vw;
    line-height: 2vw;
}
.content-part .img-part{
    width: 24vw;
}
.warp section .content-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 41vw;
    letter-spacing: .2px;
}
.content-center .title-c{
    font-size: 1.9vw;
    font-weight: 500;
    margin-top: 5vw;
}
.content-center .desc-c{
    font-size: .9vw;
    margin: 1vw 0 2vw;
}
.content-center .img-c{
    width: 55%;
}
.quickly-pay .desc{
    margin-bottom: 1.5vw;
}
.banks{
    text-align: center;
    margin: 6vw 0;
}
.banks .logo{
    width: 23vw;
}
.banks .title{
    font-size: 2vw;
    margin: 3vw auto .5vw;
}
.banks .desc{
    font-size: .9vw;
    color: #666;
}
.banks ul{
    display: flex;
    width: 60%;
    margin: 3vw auto;
    flex-wrap: wrap;
    justify-content: space-between;
}
.banks ul li img{
    width: 11vw;
}
/* 支持设备 */
.support-device{
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: .2px;
}
.support-device .title{
    font-size: 1.9vw;
    font-weight: 500;
    margin-top: 6vw;
}
.support-device .phone-list{
    width: 60%;
    padding-bottom: 7vw;
}
.support-device .phone-list ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.support-device .phone-list ul li{
    text-align: center;
    margin-top: 2.5vw;
    width: 25%;
}
.support-device .phone-list ul li img{
    height: 10vw;
}
.support-device .phone-list ul li p{
    font-size: 1.2vw;
    line-height: 4vw;
}
.more{
    font-size: .8vw;
    letter-spacing: .4px;
    position: absolute;
    bottom: 3vw;
    left: 51%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.more img{
    width: 1.1vw;
    position: relative;
    top: .2vw;
}
/* video */
#video-ele{
    width: 100%;
    border: 0;
    overflow: hidden;
}
</style>